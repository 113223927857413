<template>
  <div>
    <MasterHeader />
    <sign-up-modal v-if="hasNewSignupFlow"/>
    <div class="container mb-4">
      <div class="row d-flex justify-content-md-center">
        <div class="col-12 text-md-center mb-4">
          <h4 class="col-6 mx-auto">Find the right thesis topic with the right group of students</h4>
        </div>       
        <!-- <div class="col-12 col-md-3 align-self-center">
          <label class="color-third" for="filterSemester"
            >Field of interest</label
          >
          <multiselect v-model="user.interests" :options="jobsData" :showLabels="false" group-values="subjobs" group-label="parent" placeholder="Type to search" track-by="name" label="name"><span slot="noResult">Oops! No elements found. Consider changing the search query.</span></multiselect>
        </div> -->
        <div class="col-12 col-md-3 align-self-center">
          <label class="color-third" for="filterSemester"
            >Collaboration semester</label
          >
          <select
            name=""
            class="form-control"
            id="filterSemester"
            v-model="filterSemester"
          >
            <option value="All">All</option>
            <option v-for="(semester, index) in filters.semesters" :key="index">
              {{ semester }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-3 align-self-center">
          <label class="color-third" for="filterDegree">Degree</label>
          <select class="form-control" id="filterDegree" v-model="filterDegree">
            <option value="All">All</option>
            <option v-for="(degree, index) in filters.degrees" :key="index">
              {{ degree }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="container container-collaborations">
      <div class="collaborations">
        <div class="card-columns" v-if="filteredList.length > 0">
          <!--  -->
          <router-link
            :to="'/collaborations/' + collab.id"
            class="card"
            v-for="(collab, index) in filteredList"
            :key="index"
            :class="
              collab.collaboration.members.includes(user.userId)
                ? 'user-owned'
                : ''
            "
          >
            <div class="card-image">
              <img
                :src="
                  '/assets/institutions/' +
                    collab.collaboration.institution.id +
                    '.png'
                "
                class="card-img-top"
                :title="collab.collaboration.institution.name"
              />
            </div>
            <div class="card-body">
              <div class="card-area">
                <small>{{ cutText(collab.collaboration.area, 60) }}</small>
              </div>
              <hr />
              <h2 class="card-title">
                {{ cutText(collab.collaboration.title, 100) }}
              </h2>
              <h6 class="card-subtitle">{{ collab.collaboration.subtitle }}</h6>
                <ul v-if="collab.collaboration.keywords" class="card-keywords pt-2">                    
                    <li
                      class="keyword"
                      v-for="(keyword, index) in collab.collaboration.keywords"
                      :key="index"
                      >{{ keyword }}</li>
                  </ul>
              <p class="card-text">
                {{ cutText(htmlToText(collab.collaboration.content), 300) }}
              </p>
              <div class="card-bottom mt-4">
                <span>{{ collab.collaboration.degree }}</span
                ><span class="divider">|</span>
                <span>{{ collab.collaboration.targetSemester }}</span
                ><span class="divider">|</span>
                {{ collab.collaboration.members.length }} members
                <!-- <span class="badge" v-if="collab.collaboration.members.includes(user.userId)">yours</span> -->
                <span class="btn float-right">View</span>
              </div>
            </div>
          </router-link>
        </div>
        <div v-else>
          <h4 class="text-center color-third mt-5">
            Sorry! - But we didn't match any collaborations to your filters. Try
            adjusting your filters.
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import MasterHeader from "@/components/MasterHeader.vue";
import {storage} from '../../firebase';
import SignUpModal from '../../components/signUpModal.vue';



export default {
  components: {
    MasterHeader,
    SignUpModal,
    
  },
  data() {
    return {
      filterDegree: "All",
      filterSemester: "All",       
      jobsData:false,
      collaborationList: this.$store.state.collaboration,
    };
  },
  computed: {
    ...mapState(["user"]), 
        hasNewSignupFlow(){
      const vm = this
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false
      const isStudent = vm.$store.getters.role === 'Student'
        if (!isValid && isStudent) {
          localStorage.setItem('notSignedUp', 1);
        }
        if (isValid) {
          localStorage.removeItem('notSignedUp');
        }
      return true
    },  
    filters: function() {        
      var vm = this;
      var result = { degrees: [], semesters: [] };
      console.log(result)
      var collection = vm.collaborationList.collaborations;
      if (typeof collection !== typeof undefined) {
        collection.forEach((item) => {
          //fill degress
          if (!result.degrees.includes(item.collaboration.degree)) {
            result.degrees.push(item.collaboration.degree);
          }
          //fill semesters
          if (!result.semesters.includes(item.collaboration.targetSemester)) {
            result.semesters.push(item.collaboration.targetSemester);
          }
          
          
        });

        result.degrees.sort();
        result.semesters.sort();
      }
      return result;
    },
    filteredList: function() {     
      var vm = this;
      var collaborationFilter = vm.proposalFilter;
      var collection = vm.collaborationList.collaborations;
      var result = collection;     

      if (vm.filterDegree !== "All") {
        result = collection.filter((item) => {
          return item.collaboration.degree === vm.filterDegree;
        });
      }

      if (vm.filterSemester !== "All") {
        result = result.filter((item) => {
          return item.collaboration.targetSemester === vm.filterSemester;
        });
      }
     

      return result;
    },
    jobListLink: () =>  {
            return storage.refFromURL('gs://excelerate2020.appspot.com/Interestfields.json').getDownloadURL()
        },
  },
  created() {
    // console.log("created");
    this.$store.dispatch("getCollaborations");
    
  },
  mounted() {
    this.loadJobs();
    // console.log("mounted")
  },
  
  methods: {
     async loadJobs(){
       console.log("loadjobs",jobListLink )
      let jobListLink = await this.jobListLink;
      fetch(jobListLink,{
          method:'GET'
      }).then(res=>res.json()).then(result=>{
          let jobs = [];
          result.forEach(parent=>{
              parent.field_one.forEach(stream=>{ 
                  let ctgsSorted = stream.field_two.sort((a,b)=>a.name.localeCompare(b.name));
                  ctgsSorted.forEach(ctgs=>{
                      let subjobs =[];
                      ctgs.field_three.forEach(job=>{
                          subjobs.push({__id:job.id,name:job.name,catgeory:ctgs.id,catgeory_name:ctgs.name,stream:stream.id,parent:parent.id})
                      })
                      jobs.push({id:ctgs.id,name:ctgs.name,subjobs:subjobs,description:ctgs.description});
                      
                  });
                  
              })
          })
          this.jobsData= jobs.sort((a,b)=>a.name.localeCompare(b.name));
        });
      },
    moment: function() {
      return moment();
    },
    cutText: function(text, characters) {
      if(!text) return
      return text.length > characters
        ? text.substring(0, characters) + "..."
        : text;
    },
    htmlToText(html) {
      let div = document.createElement("div");
      div.style.display = "none";
      div.innerHTML = html;
      return div.innerText;
    },
  },
};
</script>

